import React, { createContext, useContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage, useQueryParams } from '../hooks';
import ApiManager from '../ApiManager';
import VersionUtility from 'VersionUtility';

const LOCAL_STORE_USER_KEY = 'user';
const LOCAL_STORE_ACCOUNTS_KEY = 'accounts';

const authContext = createContext();
const authAccountsContext = createContext();
const authMinimalContext = createContext();
const authFuncsContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage(LOCAL_STORE_USER_KEY, null);
  const [accounts, setAccounts] = useLocalStorage(LOCAL_STORE_ACCOUNTS_KEY, []);
  const { token } = useQueryParams();

  // callback ref pattern
  // see https://epicreact.dev/the-latest-ref-pattern-in-react/
  const setUserRef = useRef(setUser);
  const setAccountsRef = useRef(setAccounts);
  React.useLayoutEffect(() => {
    setUserRef.current = setUser;
    setAccountsRef.current = setAccounts;
  });

  // syncs all tabs wrt the logged user
  useEffect(() => {
    const handleStorageChange = (e) => {
      // if the change was on the right key and is different from what we have
      if (e.key === LOCAL_STORE_USER_KEY) {
        if (!isEqual(e.oldValue, e.newValue)) {
          setUserRef.current(JSON.parse(e.newValue));
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // returns the user if it exists in the array, else returns false
  const isUserAccountRegistered = (accounts, user) => {
    return accounts.find((account) => account.id === user.id) ?? false;
  };

  const signout = useCallback((cb) => {
    setUserRef.current(null);
    cb && cb();
  }, []);

  const switchAccount = useCallback((user, cb) => {
    setUserRef.current(user);

    cb && cb();
  }, []);

  const removeAccount = useCallback(
    (userToDelete, cb) => {
      const index = accounts.findIndex((account) => account.id === userToDelete.id);
      if (index > -1) {
        accounts.splice(index, 1);
        setAccountsRef.current(accounts);
        try {
          localStorage.setItem(LOCAL_STORE_ACCOUNTS_KEY, JSON.stringify(accounts));
        } catch (e) {
          console.warn(e);
        }
      }
      cb && cb();
    },
    [accounts]
  );

  const minimalUser = useMemo(
    () => (user?.token ? { id: user.id, token: user.token, username: user.username } : null),
    [user?.id, user?.token, user?.username]
  );

  const fetchUserInfo = useCallback(
    async (customUser = null, cb = null, fetchPaymentMethod = false) => {
      const authUser = customUser ?? minimalUser;

      console.log('here', authUser);

      if (!authUser) {
        return;
      }
      console.log('auth user', authUser);
      let info;
      let history;
      let paymentMethod;
      if (authUser?.token.includes('epat_') && window.location.pathname.includes('/view')) {
        info = {};
        history = null;
      } else {
        console.log('in else');
        try {
          if (fetchPaymentMethod) {
            [info, history, paymentMethod] = await Promise.all([
              ApiManager.get('/v3/account', null, authUser),
              ApiManager.get('/v3/account/history', null, authUser),
              ApiManager.get('/v3/account/stripe/paymentMethod', null, authUser),
            ]);
            info = VersionUtility.convertAccount(info);
            info.paymentMethod = paymentMethod;
          } else {
            [info, history] = await Promise.all([
              ApiManager.get('/v3/account', null, authUser),
              ApiManager.get('/v3/account/history', null, authUser),
            ]);
            info = VersionUtility.convertAccount(info);
          }
        } catch (error) {
          console.warn(error);
          if (error.status === 401 || error.status === 403) {
            signout(cb);
          }
          return false;
        }
      }
      let newUser = { ...minimalUser, ...info };

      newUser.token = authUser.token;
      newUser.history = history;

      setUserRef.current((prevUser) => {
        if (customUser || prevUser?.id === newUser?.id) {
          return { ...prevUser, ...newUser };
        } else {
          return prevUser;
        }
      });
      cb && cb({ ...authUser, ...newUser });

      return newUser;
    },
    [minimalUser, signout]
  );

  const signin = useCallback(
    (user, cb) => {
      console.log('signing in');
      const newcb = (user) => {
        if (user) {
          if (!isUserAccountRegistered(accounts, user)) {
            setAccountsRef.current([...accounts, user]);
          } else {
            // if the user is in the accounts array, we should remove the old object and put the new one with the new token
            const invalidUserIndex = accounts.findIndex((account) => account.id === user.id);
            accounts.splice(invalidUserIndex, 1);
            setAccountsRef.current([...accounts, user]);
          }
        }

        cb && cb();
      };

      fetchUserInfo(user, newcb);
    },
    [accounts, fetchUserInfo]
  );

  const [pollNumber /* , setPollNumber */] = useState(0);

  const currentUserId = useRef(null);
  useEffect(() => {
    if (currentUserId.current !== user?.id) {
      currentUserId.current = user?.id;
    } else if (user?.id) {
      fetchUserInfo();
    }
  }, [pollNumber, fetchUserInfo, user?.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      //setPollNumber((k) => k + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const location = useLocation();
  const history = useHistory();

  const firstTime = useRef(true);

  useEffect(() => {
    if (token) {
      firstTime.current = false;
      signin({ token: token });

      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('token');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [token, history, location.search, signin]);

  useEffect(() => {
    if (firstTime.current && !token) {
      firstTime.current = false;

      fetchUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserInfo]);

  const memoizedContextUser = useMemo(
    () => ({
      user: user,
    }),
    [user]
  );
  const memoizedContextMinimalUser = useMemo(
    () => ({
      minimalUser: minimalUser,
    }),
    [minimalUser]
  );

  return (
    <authAccountsContext.Provider value={accounts}>
      <authContext.Provider value={memoizedContextUser}>
        <authMinimalContext.Provider value={memoizedContextMinimalUser}>
          <authFuncsContext.Provider value={{ signin, switchAccount, removeAccount, signout, fetchUserInfo }}>
            {children}
          </authFuncsContext.Provider>
        </authMinimalContext.Provider>
      </authContext.Provider>
    </authAccountsContext.Provider>
  );
};

function useAuth() {
  const context = useContext(authContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a UserProvider');
  }
  return context.user;
}
function useAccountsAuth() {
  const context = useContext(authAccountsContext);
  if (context === undefined) {
    throw new Error('useAccountsAuth must be used within a UserProvider');
  }
  return context;
}

function useMinimalAuth() {
  const context = useContext(authMinimalContext);
  if (context === undefined) {
    throw new Error('useMinimalAuth must be used within a UserProvider');
  }
  return context.minimalUser;
}

function useAuthFuncs() {
  const context = useContext(authFuncsContext);
  if (context === undefined) {
    throw new Error('useAuthFuncs must be used within a UserProvider');
  }
  return context;
}

function withAuth(Component) {
  return function WrapperComponent(props) {
    const user = useAuth();
    return <Component {...props} authUser={user} />;
  };
}

function withMinimalAuth(Component) {
  return function WrapperComponent(props) {
    const minimalUser = useMinimalAuth();
    return <Component {...props} authMinimalUser={minimalUser} />;
  };
}

function withAuthFuncs(Component) {
  return function WrapperComponent(props) {
    const authFuncs = useAuthFuncs();
    return <Component {...props} {...authFuncs} />;
  };
}

export {
  UserProvider,
  useAuth,
  useAccountsAuth,
  useMinimalAuth,
  useAuthFuncs,
  withAuth,
  withAuthFuncs,
  withMinimalAuth,
};
