import React from 'react';

import { Box } from '@mui/material';
import ApiManager from 'ApiManager';

const SIZE = 24;
const REPEAT = 200;
const OFFSET = 2;

export const GET_GRADIENT = ({ type, color, width = 1, backgroundColor = 'transparent' }) =>
  type === 'checkerboard'
    ? {
        backgroundColor: type === 'solid' ? color : backgroundColor,
        backgroundImage: `repeating-linear-gradient(45deg, ${color} 25%, transparent 25%, transparent 75%, ${color} 75%, ${color}),
          repeating-linear-gradient(45deg, ${color} 25%, transparent 25%, transparent 75%, ${color} 75%, ${color})`,
        backgroundSize: `${Math.round(SIZE / (REPEAT / width) + OFFSET)}px ${Math.round(
          SIZE / (REPEAT / width) + OFFSET
        )}px`,
        backgroundPosition: `0 0, ${Math.round(SIZE / (REPEAT / width) + OFFSET) / 2}px ${
          Math.round(SIZE / (REPEAT / width) + OFFSET) / 2
        }px`,
        backgroundRepeat: 'repeat',
      }
    : {
        backgroundColor: type === 'solid' ? color : backgroundColor,
        backgroundImage: `repeating-linear-gradient(
          ${type === 'diagonal' ? '-45deg' : type === 'horizontal' ? '180deg' : type === 'vertical' ? '90deg' : ''},
          ${color} 0px,
          ${color} ${Math.round(SIZE / (REPEAT / width) + OFFSET)}px,
          ${backgroundColor} ${Math.round(SIZE / (REPEAT / width) + OFFSET)}px,
          ${backgroundColor} ${Math.round(SIZE / (REPEAT / width) + OFFSET) * 2}px,
          ${color} ${Math.round(SIZE / (REPEAT / width) + OFFSET) * 2 + 1}px
        )`,
      };

const PatternIcon = ({ type = 'solid', color, width, backgroundColor, borderRadius = 1, icon }) => {
  const body = { color: color, alpha: 1, width: 1, name: icon?.name };
  const params = new URLSearchParams(body).toString();
  const url = ApiManager.apiUrl + '/v3/image/icon?' + params;
  return (
    <>
      {icon ? (
        <img src={url} height={SIZE} width={SIZE} />
      ) : (
        <Box
          sx={(theme) => ({
            height: SIZE,
            width: SIZE,
            borderRadius,
            ...GET_GRADIENT({ type, color: color ?? theme.palette.action.active, backgroundColor, width }),
          })}
        />
      )}
    </>
  );
};

export default PatternIcon;
